*,
*::after,
*::before {
  box-sizing: border-box;
}

.App {
  position: relative;
  height: 100%;
  width: 100%;
}

.hide {
  opacity: 0;
}

.fade-in {
  animation: fadeInUp ease-in 1.5s;
}
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 2.5rem, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

:root {
  font-size: 15px;
  width: 100%;
  --color-bg: white;
  --color-text: black;
  --theme-color-1: #141414;
  --theme-color-2: #3b3b3b;
  --font-normal: proxima-nova, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: var(--font-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
a {
  cursor: pointer;
}
.container {
  margin: 0 auto;
  width: 800px;
  @media screen and (max-width: 700px) {
    padding: 1.3rem;
    width: 100%;
    margin: 0;
  }
}
.header {
  width: 100%;
  &__logo {
    height: 3rem;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2rem 4rem;
    align-items: center;
    @media screen and (max-width: 500px) {
      padding: 1rem;
    }
  }
  &__title-wrapper {
    &--mobile {
      display: none;
      @media screen and (max-width: 500px) {
        display: block;
        width: 100%;
        span {
          text-align: center;
        }
      }
    }
    &--desktop {
      display: block;

      justify-content: center;
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }

  &__title {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__subtitle {
    display: block;
    font-size: 0.8rem;
  }

  &__contact-button {
    background: transparent;
    border-radius: 7px;
    border: 2px solid var(--theme-color-1);
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--theme-color-1);
    text-decoration: none;
    transition: all ease-in-out 0.5s;
    &:hover {
      background: var(--theme-color-1);
      color: white;
    }
  }
}
.hero {
  display: flex;
  justify-content: center;
  flex-direction: column;
  span {
    margin: 0;
    padding: 0;
  }
  img {
    width: 100%;
  }
  &__message {
    text-align: center;
    font-size: 5.6rem;
    margin: 0;
    @media screen and (max-width: 500px) {
      font-size: 2.6rem;
    }
  }
  &__copy {
    text-align: center;
    font-size: 1.3rem;
    color: var(--theme-color-2);
    @media screen and (max-width: 500px) {
      font-size: 1.1rem;
      margin-top: 0;
    }
  }
  &__cta-wrapper {
    text-align: center;
    margin: 2rem 0;
  }
  &__cta {
    margin: 0 auto;
    background: var(--theme-color-1);
    color: white;
    border-radius: 7px;
    border: 2px solid var(--theme-color-1);
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    transition: all ease-in-out 0.5s;
    &:hover {
      background: transparent;
      color: var(--theme-color-1);
    }
  }
}

.about-product {
  width: 100%;
  background-color: #eceff5;
  padding: 2rem;
  @media screen and (max-width: 500px) {
    padding: 0;
  }
  &__heading {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  &__copy {
    font-size: 1rem;
    text-align: center;
    color: var(--theme-color-2);
  }
  &__info-boxes {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
  }
  &__info-box {
    width: 30%;
    background-color: white;
    position: relative;
    margin: 1%;
    @media screen and (max-width: 500px) {
      width: 100%;
      margin: 1.1rem 0;
    }
    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  &__info-box-container {
    position: absolute;
    padding: 0.8rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    img {
      width: 100%;
      margin-bottom: auto;
    }
  }

  &__info-box-copy {
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
    height: 3rem;
  }
}

.contact {
  text-align: center;
  padding-bottom: 3rem;
  span {
    display: block;
  }
  &__header {
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  &__container {
    margin: 2rem 0;
    font-size: 1.2rem;
  }
  &__type {
    text-transform: uppercase;
    font-weight: 600;
  }
  &__copyright {
    display: inline-block;
    margin-top: 2rem;
  }
}
